/*
 *  @TODO
 *    - Use a form
 */

import { ChangeEvent, useEffect, useState } from 'react';

import { ApolloError } from '@apollo/client';
import { COMMON_LIBRARY_CONSTANTS } from '@netfront/common-library';
import { ButtonIconOnly, Dialog, Input, RadioButtonGroup, Spinner } from '@netfront/ui-library';
import { useRouter } from 'next/router';

import { RestrictionsTable } from './RestrictionsTable';
import { checkIfRestrictionExists, getRadioButtonItems } from './RestrictionsView.helpers';

import { useApiKeyContext } from '../../../../contexts';
import {
  API_KEY_RESTRICTION_NONE,
  IApproveRestrictionOnCompletedResponse,
  ICreateRestrictionOnCompletedResponse,
  IDeleteRestrictionOnCompletedResponse,
  IHandleApproveRestrictionParams,
  IHandleDeleteRestrictionParams,
  useApproveRestriction,
  useCreateRestriction,
  useDeleteRestriction,
  useGetApiKeysByProject,
  useToast,
} from '../../../../hooks';
import { IApiKey, RestrictionType, RESTRICTION_TYPES } from '../../../../interfaces';

const RestrictionsView = () => {
  const { query } = useRouter();
  const { projectId } = query;
  const { dispatch, state: apiKeys = [] } = useApiKeyContext();
  const { handleToastError } = useToast();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [restrictionType, setRestrictionType] = useState<RestrictionType>('NONE');
  const [restrictionValue, setRestrictionValue] = useState<string>();
  const [selectedApiKey, setSelectedApiKey] = useState<IApiKey>();
  const [selectedRestrictionId, setSelectedRestrictionId] = useState<number>();
  const [isApproveRestrictionModalOpen, setIsApproveRestrictionModalOpen] = useState<boolean>(false);
  const [approveRestrictionValues, setApproveRestrictionValues] = useState<IHandleApproveRestrictionParams>();

  const handleApiRestrictionTypeClick = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setErrorMessage('');
    setRestrictionType(value as RestrictionType);
  };

  const handleApolloError = (error?: ApolloError) => {
    if (!error) {
      return;
    }

    handleToastError({
      error,
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleCreateRestrictionButtonClick = (value: string | undefined) => {
    if (!value) {
      setErrorMessage('A restriction is required');
      return;
    }

    const hasMatchingRestriction = checkIfRestrictionExists({ value, restrictions});

    if (hasMatchingRestriction) {
      setErrorMessage('A restriction already exists for this value');
      return;
    }

    handleCreateRestriction({
      apiKeyId,
      type: restrictionType,
      value,
    });
  };



  const handleCreateRestrictionCompleted = (data?: ICreateRestrictionOnCompletedResponse) => {
    if (!(data && selectedApiKey)) {
      return;
    }

    const { restriction: returnedRestriction } = data;

    dispatch({
      payload: {
        apiKeyId: selectedApiKey.id,
        restriction: returnedRestriction,
      },
      type: 'addRestrictionToApiKey',
    });

    setErrorMessage('');
    setRestrictionType('NONE');
    setRestrictionValue('');
  };

  const handleCreateRestrictionError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleDeleteRestrictionCompleted = (data?: IDeleteRestrictionOnCompletedResponse) => {
    if (!(data && selectedApiKey)) {
      return;
    }

    const { isCompleted } = data;

    if (isCompleted) {
      dispatch({
        payload: {
          apiKeyId: selectedApiKey.id,
          restrictionId: Number(selectedRestrictionId),
        },
        type: 'removeRestrictionFromApiKey',
      });

      return;
    }

    const {
      MESSAGES: {
        ERROR: { UNEXPECTED },
      },
    } = COMMON_LIBRARY_CONSTANTS;

    handleToastError({
      error: new Error(UNEXPECTED),
      shouldUseFriendlyErrorMessage: true,
    });
  };

  const handleDeleteRestrictionError = (error?: ApolloError) => {
    handleApolloError(error);
  };

  const handleDeleteRestrictionClick = ({ id }: IHandleDeleteRestrictionParams) => {
    handleDeleteRestriction({
      id,
    });

    setSelectedRestrictionId(id);
  };

  const handleRestrictionChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;

    setRestrictionValue(value);
  };

  const { handleGetApiKeysByProject, isLoading: isGetApiKeysByProjectLoading = false } = useGetApiKeysByProject({
    fetchPolicy: 'cache-first',
    onCompleted: ({ apiKeys: returnedApiKeys }) => {
      if (!selectedApiKey) {
        return;
      }

      setSelectedApiKey(returnedApiKeys.find(({ guid }) => selectedApiKey.guid === guid));
    },
    onError: (error) => {
      handleToastError({
        error,
        shouldUseFriendlyErrorMessage: true,
      });
    },
  });

  const { handleApproveRestriction, isLoading: isApproveRestrictionLoading = false } = useApproveRestriction({
    onCompleted: ({ isCompleted }: IApproveRestrictionOnCompletedResponse) => {
      if (!(isCompleted && selectedApiKey)) {
        return;
      }

      handleGetApiKeysByProject({
        projectId: String(projectId),
      });
    },
    onError: (error?: ApolloError) => handleApolloError(error),
  });

  const handleApproveRestrictionDialogCloseClick = () => {
    setIsApproveRestrictionModalOpen(false);
    setApproveRestrictionValues(undefined);
  };

  const handleApproveUrlRestriction = ({ id, validate }: IHandleApproveRestrictionParams) => {
    setApproveRestrictionValues({ id, validate });
    setIsApproveRestrictionModalOpen(true);
  };

  const { handleCreateRestriction, isLoading: isCreateRestrictionLoading = false } = useCreateRestriction({
    onCompleted: handleCreateRestrictionCompleted,
    onError: handleCreateRestrictionError,
  });

  const { handleDeleteRestriction, isLoading: isDeleteRestrictionLoading = false } = useDeleteRestriction({
    onCompleted: handleDeleteRestrictionCompleted,
    onError: handleDeleteRestrictionError,
  });

  useEffect(() => {
    if (!apiKeys.length) {
      return;
    }
    const currentApiKey = apiKeys.find(({ isSelected }) => isSelected);

    setSelectedApiKey(currentApiKey);
  }, [apiKeys]);

  if (!selectedApiKey) {
    return null;
  }

  const { id: apiKeyId, restrictions = [] } = selectedApiKey;

  const isLoading = isCreateRestrictionLoading || isDeleteRestrictionLoading || isApproveRestrictionLoading || isGetApiKeysByProjectLoading;

  return (
    <>
      <Spinner isLoading={isLoading} />

      <Dialog
        isOpen={isApproveRestrictionModalOpen}
        title="Approve restriction"
        onClose={handleApproveRestrictionDialogCloseClick}
        onConfirm={() => {
          if (!approveRestrictionValues) {
            return;
          }

          handleApproveRestriction(approveRestrictionValues);
        }}
      >
        <span>By clicking confirm, you will approve the selected restriction</span>
      </Dialog>

      <div className="p-4">
        <section className="pb-8">
          <h3 className="mb-2">API restrictions</h3>

          <div className="flex items-center">
            <RadioButtonGroup
              items={getRadioButtonItems(RESTRICTION_TYPES)}
              name="api-currentRestrictions-type"
              selectedValue={restrictionType}
              onChange={handleApiRestrictionTypeClick}
            />
          </div>
        </section>

        {restrictionType !== 'NONE' ? (
          <section className="pb-8">
            <h3 className="mb-2">Please enter your restriction below</h3>

            <div className="mb-2">
              <Input
                errorMessage={errorMessage}
                id="api-key-restriction"
                labelText=""
                name="api-key-restriction"
                type="text"
                value={restrictionValue}
                onChange={(event: ChangeEvent<HTMLInputElement>) => handleRestrictionChange(event)}
              />
            </div>

            <div className="flex items-center justify-end">
              <ButtonIconOnly
                iconId="id_plus_icon"
                text="Add restriction url"
                isIconBorderVisible
                onClick={() => handleCreateRestrictionButtonClick(restrictionValue)}
              />
            </div>
          </section>
        ) : null}

        <section className="pb-8">
          <h3 className="mb-2">Approved restrictions</h3>

          {restrictions.length ? (
            <RestrictionsTable
              restrictionItems={restrictions.filter(({ hasBeenApproved }) => hasBeenApproved)}
              isApproved
              onRemoveClick={(id: number) => handleDeleteRestrictionClick({ id })}
            />
          ) : (
            <p>{API_KEY_RESTRICTION_NONE}</p>
          )}
        </section>

        <section className="pb-8">
          <div className="flex items-center justify-between w-full">
            <h3 className="mb-2">Requires Approval</h3>
          </div>

          {restrictions.length ? (
            <RestrictionsTable
              restrictionItems={restrictions.filter(({ hasBeenApproved }) => !hasBeenApproved)}
              onApproveClick={(id: number) => handleApproveUrlRestriction({ id, validate: true })}
            />
          ) : (
            <p>{API_KEY_RESTRICTION_NONE}</p>
          )}
        </section>
      </div>
    </>
  );
};

export { RestrictionsView };
