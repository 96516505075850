import React, { useEffect, useState } from 'react';

import { Spacing, Table, ButtonIconOnly, FlexContainer, Tooltip } from '@netfront/ui-library';

import { RESTRICTION_TYPE_FRIENDLY_LABEL_MAP } from '../RestrictionsView.constants';

import { RestrictionsTableProps } from './RestrictionsTable.interfaces';

import { IRestriction } from '../../../../../interfaces';

const RestrictionsTable = ({ onApproveClick, onRemoveClick, restrictionItems = [], isApproved =  true }: RestrictionsTableProps) => {
  const [items, setItems] = useState<IRestriction[]>([]);

  useEffect(() => {
    setItems(restrictionItems);
  }, [restrictionItems]);
  return (
    <>
      {items.length > 0 && (
        <Spacing>
          <Table
            columns={[
              {
                accessor: 'restriction',
                Cell: ({ value }: { value: string }) => {
                  const truncatedText = `${value.slice(0, 40)}...` ;

                  if (value.length <= 40) return <span>{value}</span>;

                  return (
                    <FlexContainer justifyContent="space-between" isFullWidth>
                      <span>{truncatedText}</span>
                      <Tooltip
                        additionalClassNames="c-style-settings__tooltip"
                        iconId="id_tooltip_icon"
                        placement="left"
                        text={value}
                        isShownOnHover
                      />
                    </FlexContainer>
                  );
                },
                Header: () => <div>Restriction</div>,
                width: '75%',
              },
              {
                accessor: 'type',
                Cell: ({ value }: { value: "NONE" | "HTTP_REFERER" | "IP_ADDRESS" | "MOBILE_APP" }) => <span>{RESTRICTION_TYPE_FRIENDLY_LABEL_MAP[value]}</span>,
                Header: () => <div>Type</div>,
                width: '20%',
              },
              {
                accessor: 'settingsButtonData',
                width: '5%',
                Header: () => <div className="h-hide-visually">{isApproved ? 'Remove': 'Approve'}</div>,
                Cell: ({
                  value: {
                    handleApproval,
                    handleRemove,
                    id,
                  } }: {
                  value : {
                    handleApproval?: (id: number) => void;
                    handleRemove?: (id: number) => void;
                    id: number;
                  }
                }) => (
                  <FlexContainer justifyContent="center" isFullWidth>
                    {
                      isApproved ? (
                        <ButtonIconOnly
                          iconId="id_bin_icon"
                          text="Remove restriction"
                          onClick={() => handleRemove?.(id)}
                        />

                      ) : (
                        <ButtonIconOnly
                          iconId="id_tick_icon"
                          text="Approve restriction"
                          onClick={() => handleApproval?.(id)}
                        />
                      )
                    }
                  </FlexContainer>
                ),
              },
            ]}
            data={items.map(({ id, value, type }) => (
              {
                id: id,
                restriction: value,
                type,
                settingsButtonData: {
                  id,
                  handleApproval: onApproveClick,
                  handleRemove: onRemoveClick,
                },
              }
            ))}
          />
        </Spacing>
      )}
    </>
  );
};

export { RestrictionsTable };
