import { IRadioButtonItem } from '@netfront/ui-library';
import { IRestriction, RestrictionType } from 'interfaces';

import { RESTRICTION_TYPE_FRIENDLY_LABEL_MAP } from './RestrictionsView.constants';

const getRadioButtonItems = (data: readonly string[]): IRadioButtonItem[] => {
  return data.map((item) => {
    const option = {
      id: item,
      labelText: RESTRICTION_TYPE_FRIENDLY_LABEL_MAP[item as RestrictionType],
      value: item,
    };

    return option;
  });
};

const normalizeString = (str: string) => str.trim().replace(/\/$/, ''); // Removes trailing slashes

const checkIfRestrictionExists = ({restrictions = [], value }: { restrictions?: IRestriction[]; value: string }): boolean => {
  const normalizedValue = normalizeString(value);
  return restrictions.some(item => normalizeString(item.value) === normalizedValue);
};

export { getRadioButtonItems, checkIfRestrictionExists };
